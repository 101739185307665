/*import logo from './logo.svg';*/
import './App.css';
import React from 'react';
// import './bootstrap.min.css';
// import './fade-down.css';
// import './webslidemenu.css';
// import './demo.css';
// import './propacheaderv1.css';
// import './rightCart.css';
// import './rightCartItems.css';
// import './textslider.css';
// import './white-gry.css';
// import './marzia.css';
import './style_header.css';


function App() {
  return (
    <React.Fragment>
    <>
  {/* Mobile Header */}
  <div className="wsmobileheader clearfix top28">
    <a id="wsnavtoggle" className="wsanimated-arrow">
      <span />
    </a>
    <span className="smllogo">
      <a href="/fr/fr">
        <img alt=""
          id="imgHeaderMobile"
          src="/menu/images/logo-pagloo-2023.png"
          width={80}
          
        />
      </a>
    </span>
    <div className="wsmobileIconBib clearfix">
      <a href="tel:017418034">
        <iconify-icon icon="mi:call" />
      </a>
      <a href="/fr/login?back=my-account">
        <iconify-icon icon="mi:user" id="userIconHeader" />
      </a>
      <a href="#" className="clickToViewRightCart" id='mobileCartIconHeader23'>
        {/* <em className="roundpoint">8</em>
        <iconify-icon
          icon="ph:shopping-cart-simple-bold"
          id="chartIconHeader"
        /> */}
      </a>
      <span className="hidetxt">Shopping Cart</span>
    </div>
  </div>
  {/* Mobile Header */}

  {/*rigt cart inizio, vine iniettato lato dexa il codice minicart(reperibile dentro progetto html github) dentro all'id minicart-container*/}
  <div className="minicart-container" id='minicart-container'></div>
  <div className="overlay" />
  {/* right cart fine */}
  <div className="headersticky">
    <div className="headtoppart clearfix">
      <div className="headerwp clearfix">
        <div className="headertopleft clearfix">
          <div className="address clearfix">
            <span>
              <a href="/fr/content/7-nous-contacter">Besoin d'aide ?</a>
            </span>
            <span>
              <a href="/fr/content/8-faq">FAQ</a>
            </span>
            <span>
              
              <iconify-icon icon="ion:call-sharp" />
              <a href="tel:017418034">
                
              Numéro gratuit <span className="bolder">017418034</span>
              </a>
            </span>
          </div>
        </div>
        <div id="slider_textsli">
          <div className="container_textsli">
            <div className="slide_textsli">
              <p>
                <span className="mobHiddenBib valorizza">
                  
                Produits en livraison immédiate
                </span>
                <span className="mobHiddenBib" style={{ padding: "0px 5px" }}>
                Expédiés en 24/48h
                </span>
                <span className="mobHiddenBib primordine">
                  
                  -30% sur la première commande
                </span>
              </p>
            </div>
            <div className="slide_textsli">
              <p>-30% sur la première commande</p>
            </div>
            <div className="slide_textsli">
              <p>Expédiés en 24/48h</p>
            </div>
            <div className="slide_textsli">
              <p>Produits en livraison immédiate</p>
            </div>
            <div className="slide_textsli">
              <p>-30% sur la première commande</p>
            </div>
            <div className="slide_textsli">
              <p>Produits en livraison immédiate</p>
            </div>
            <div className="slide_textsli">
              <p>-30% sur la première commande</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*inizio header--------*/}
    <div id="headerBib">
      <div className="containerHeaderBib">
        <section className="columns">
          <div className="column column1">
            <a href="/fr/fr">
              <img alt="" src="/menu/images/logo-pagloo-2023.png" />
            </a>
          </div>
          <div className="column column2">
            <form className="topmenusearch" id="searchBarHeader2023">
              <input placeholder="Chercher un article..." />
              <button className="btnstyle">
                
                <iconify-icon icon="majesticons:search-line" />
              </button>
            </form>
          </div>
          <div className="column column3">
            <nav className="wsmenu clearfix">
              <ul className="wsmenu-list">
                <li className="wscarticon clearfix clickToViewRightCart" id='cartIconHeader23'>
                  {/* 
                  
                  disabilitato, viene iniettato lato dexa dentro all'id cartIconHeader23

                  <a href="#">
                    <iconify-icon
                      icon="fluent:cart-24-regular"
                      width={36}
                      height={36}
                      id="chartIconHeader"
                    />
                  </a>
                  <em className="roundpoint">8</em>
                  <span className="hidetxt">Shopping Cart</span>
                  <span className="testicone">Carrello</span> */}
                </li>
                <li aria-haspopup="true" className="wsshopmyaccount">
                  <a
                    href="/fr/module/obs_quickorder/quickorder"
                    title="Achat rapide"
                  >
                    
                    <iconify-icon
                      icon="streamline:interface-upload-box-1-arrow-box-download-internet-network-server-up-upload"
                      id="ordineRapidoIconHeader"
                      width={26}
                      height={32}
                    />
                  </a>
                  <span className="testicone"> Achat rapide</span>
                </li>
                <li aria-haspopup="true" className="wsshopmyaccount" id='userIconHeader23'>
                  
                  {/* 
                  
                  disabilitato, viene iniettato lato dexa dentro all'id userIconHeader23


                  <a href="#">
                    <iconify-icon
                      icon="mi:user"
                      id="userIconHeader"
                      width={28}
                      height={32}
                    />
                  </a>
                  <span className="testicone">Accedi</span>
                  <ul className="sub-menu">
                    <div className="triangolo1"> </div>
                    <div className="triangolo2"> </div>
                    <li>
                      <a href="/it/account">
                        <iconify-icon icon="mingcute:user-2-fill">
                          
                        </iconify-icon>
                        Profilo
                      </a>
                    </li>
                    <li>
                      <a href="/it/module/ttproductwishlist/mywishlist">
                        <iconify-icon icon="ph:heart-fill"> </iconify-icon>
                        Preferiti
                      </a>
                    </li>
                    <li>
                      <a href="/it/?mylogout=">
                        <iconify-icon icon="ion:log-out"> </iconify-icon> Logout
                      </a>
                    </li>
                    <div className="tooltipbox"> </div>
                    <div className="formLoginHeaderPopup">
                      <form className="login-form">
                        <h2>Hai già un account?</h2>
                        <p>Inserisci le tue credenziali</p>
                        <input type="text" placeholder="username" />
                        <input type="password" placeholder="password" />
                        <a href="/it/recupero-password" className="messagepw">
                          Hai dimenticato la password?
                        </a>
                        <button>Login</button>
                        <p className="message">
                          Nuovo cliente?
                          <a href="/it/login?create_account=1">Crea un account</a>
                        </p>
                      </form>
                    </div>
                  </ul> */}
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </div>
    </div>
  </div>
  {/*fine header----*/}
  <div className="headerfull">
    <div className="wsmain clearfix">
      <nav className="wsmenu clearfix">
        <ul className="wsmenu-list">
          <li aria-haspopup="true" id="primoItemMenu">
            <a href="#" className="navtext">
              
              <span>
                
                <iconify-icon icon="pajamas:hamburger"> </iconify-icon>Tous les produits
              </span>
            </a>
            <div
              className="wsshoptabing wtsdepartmentmenu clearfix"
              style={{ display: "block" }}
            >
              <div className="wsshopwp clearfix">
                <ul className="wstabitem clearfix">
                  <li id="scatoleVertiMenu">
                    <a href="#">
                      <img alt="" src="/menu/images/menu/1-Scatole e casse/Scatole-e-casse-30px.png" />
                      Cartons, Boîtes et Caisses
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Cartons, Boîtes et Caisses
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/520-boite-carton-pour-emballage-varie">
                                  <img alt="" src="/menu/images/menu/1-Scatole e casse/Scatole-cartone-da-imballaggio-30px.png" />
                                  Boîtes cartons pour emballages variés
                                </a>
                              </li>
                              <li>
                                <a href="/fr/521-bo%C3%AEtes-postales-pour-colis">
                                  <img alt="" src="/menu/images/menu/1-Scatole e casse/Scatole-fustellate-per-spedizione-30px.png" />
                                  Boîtes postales pour colis
                                </a>
                              </li>
                              <li>
                                <a href="/fr/523-bo%C3%AEtes-et-caisses-pour-bouteilles">
                                  <img alt="" src="/menu/images/menu/1-Scatole e casse/Scatole per bottiglie-30px.png" />
                                  Boîtes et Caisses pour bouteilles
                                </a>
                              </li>
                              <li>
                                <a href="/fr/522-caisses-en-bois-et-containers">
                                  <img alt="" src="/menu/images/menu/1-Scatole e casse/Casse-in-legno-e-scatole-container-30px.png" />
                                  Caisses en bois et Containers
                                </a>
                              </li>
                              <li>
                                <a href="/fr/525-caisses-pour-d%C3%A9m%C3%A9nagement-et-accessoires">
                                  <img alt="" src="/menu/images/menu/1-Scatole e casse/Scatole-per-trasloco-e-accessori-30px.png" />
                                  Caisses déménagement et Accessoires
                                </a>
                              </li>
                              <li>
                                <a href="/fr/524-%C3%A9tuis-de-protection-et-d-exp%C3%A9dition">
                                  <img alt="" src="/menu/images/menu/1-Scatole e casse/Scatole-per-imballaggi-protettivi-30px.png" />
                                  Étuis de protection et d'expédition
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/27-cartons-boites-et-caisses"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=520%7C522">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/1-fuorituttoFR-scatole-e-casse.jpg"
                                
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/6-Buste e tubi per spedizioni/Buste-e-tubi-per-spedizioni-30px.png" />
                      Enveloppes, Pochettes et Tubes d'expédition
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Enveloppes, Pochettes et Tubes d'expédition
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/587-pochettes-matelass%C3%A9es">
                                  <img alt="" src="/menu/images/menu/6-Buste e tubi per spedizioni/Buste-imbottite-30px.png" />
                                  Pochettes matelassées
                                </a>
                              </li>
                              <li>
                                <a href="/fr/588-tubes-d-exp%C3%A9dition-carton">
                                  <img alt="" src="/menu/images/menu/6-Buste e tubi per spedizioni/Tubi-cartone-per-spedizione-30px.png" />
                                  Tubes d'expédition carton
                                </a>
                              </li>
                              <li>
                                <a href="/fr/590-enveloppes-et-pochettes-carton">
                                  <img alt="" src="/menu/images/menu/6-Buste e tubi per spedizioni/Buste-in-carta-e-cartoncino-30px.png" />
                                  Enveloppes et Pochettes carton
                                </a>
                              </li>
                              <li>
                                <a href="/fr/589-pochettes-porte-documents">
                                  <img alt="" src="/menu/images/menu/6-Buste e tubi per spedizioni/Buste-portadocumenti-30px.png" />
                                  Pochettes porte-documents
                                </a>
                              </li>
                              <li>
                                <a href="/fr/591-pochettes-de-s%C3%A9curit%C3%A9-plastifi%C3%A9es">
                                  <img alt="" src="/menu/images/menu/6-Buste e tubi per spedizioni/Buste-di-sicurezza-plastificate-30px.png" />
                                  Pochettes de sécurité plastifiées
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/32-enveloppes-pochettes-et-tubes-d-expedition"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=591">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/6-fuorituttoFR-buste-e-tubi.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Protezione-e-riempimento-30px.png" />
                      Protection et Remplissage
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Protection et Remplissage
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/527-films-bulles">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Pluriball-30px.png" />
                                  Films bulle
                                </a>
                              </li>
                              <li>
                                <a href="/fr/528-mousse-et-poly%C3%A9thyl%C3%A8ne-expans%C3%A9">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Polietilene-espanso-30px.png" />
                                  Mousse et Polyéthylène expansé
                                </a>
                              </li>
                              <li>
                                <a href="/fr/529-protection-papier-et-carton">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Carta-e-cartone-da-imballaggio-30px.png" />
                                  Papier et Carton d'emballage
                                </a>
                              </li>
                              <li>
                                <a href="/fr/530-particules-de-calage-en-polystyr%C3%A8ne">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Polistirolo-30px.png" />
                                  Polystyrène
                                </a>
                              </li>
                              <li>
                                <a href="/fr/532-papier-de-calage">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Carta-da-riempimento-30px.png" />
                                  Papier de calage
                                </a>
                              </li>
                              <li>
                                <a href="/fr/531-coussins-et-nappes-de-coussins-d-air">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Cuscini-ad-aria-30px.png" />
                                  Coussins et Nappes de coussin d'air
                                </a>
                              </li>
                              <li>
                                <a href="/fr/533-profil%C3%A9s-coins-et-gaines-de-protection">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Profili-angolari-e-di-protezione-30px.png" />
                                  Profilés, Coins et Gaines de protection
                                </a>
                              </li>
                              <li>
                                <a href="/fr/534-films-toiles-et-sacherie-de-protection">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Teli-sacchi-e-buste-di-protezione-30px.png" />
                                  Films, Toiles et Sacherie de protection
                                </a>
                              </li>
                              <li>
                                <a href="/fr/535-protection-contre-la-corrosion-et-l-humidit%C3%A9">
                                  <img alt="" src="/menu/images/menu/2-Protezione e riempimento/Protezione-anticorrosione-e-antiumidita-30px.png" />
                                  Protection contre la corrosion et l'humidité
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/28-protection-et-remplissage"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=527%7C531">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/2-fuorituttoFR-protezione-e-riempimento.jpg"
                                
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-adesivi-e-prodotti-di-chiusura-30px.png" />
                      Rubans adhésifs et Produits de fermeture
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Rubans adhésifs et Produits de fermeture
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/547-rubans-adh%C3%A9sifs-d-emballage-polypropyl%C3%A8ne">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-da-imballaggio-in-PPL-30px.png" />
                                  Rubans adhésifs d'emballage polypropylène
                                </a>
                              </li>
                              <li>
                                <a href="/fr/548-nastri-da-imballaggio-in-pvc">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-da-imballaggio-in-PVC-30px.png" />
                                  Rubans adhésifs d'emballage PVC
                                </a>
                              </li>
                              <li>
                                <a href="/fr/555-rubans-adh%C3%A9sifs-papier-et-trame-renforc%C3%A9e">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-in-carta-e-trama-rinforzata-30px.png" />
                                  Rubans adhésifs papier et trame renforcée
                                </a>
                              </li>
                              <li>
                                <a href="/fr/549-rubans-adhesifs-de-signalisation">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-di-segnalazione-30px.png" />
                                  Rubans adhésifs de signalisation
                                </a>
                              </li>
                              <li>
                                <a href="/fr/551-rubans-adh%C3%A9sifs-de-masquage">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-adesivi-per-mascheratura-30px.png" />
                                  Rubans adhésifs de masquage
                                </a>
                              </li>
                              <li>
                                <a href="/fr/550-rubans-adh%C3%A9sifs-de-fixation-double-face">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-biadesivi-per-fissaggio-30px.png" />
                                  Rubans adhésifs de fixation double face
                                </a>
                              </li>
                              <li>
                                <a href="/fr/553-d%C3%A9vidoirs-de-ruban-adh%C3%A9sif">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Dispenser-di-nastro-adesivo-30px.png" />
                                  Dévidoirs de ruban adhésif
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/774-nastri-adesivi-speciali">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-adesivi-speciali-30px.png" />
                                  Rubans adhésifs spéciaux
                                </a>
                              </li>
                              <li>
                                <a href="/fr/552-%C3%A9lastiques-liens-et-scell%C3%A9s">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Elastici-fascette-e-sigilli-30px.png" />
                                  Élastiques, Liens et Scellés
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/751-chiodi-punti-e-graffe">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Chiodi-punti-e-graffe-30px.png" />
                                  Clous, Pointes et Agrafes
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/754-nastri-adesivi-e-prodotti-tesa">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-adesivi-e-prodotti-TESA-30px.png" />
                                  Rubans adhésifs et Produits TESA
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/753-nastri-adesivi-e-prodotti-3m">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Nastri-adesivi-e-prodotti-3M-30px.png" />
                                  Rubans adhésifs et Produits 3M
                                </a>
                              </li>
                              <li>
                                <a href="/fr/556-pistolets-%C3%A0-colle">
                                  <img alt="" src="/menu/images/menu/4-Nastri adesivi e prodotti di chiusura/Pistole-e-colle-a-caldo-30px.png" />
                                  Pistolets à colle
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/31-rubans-adhesifs-et-produits-de-fermeture"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=547%7C753%7C774">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/4-fuorituttoFR-Nastri-adesivi-e-prodotti-di-chiusura.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Pallet-estensibile-e-reggia-30px.png" />
                      Feuillards, Films étirables et Palettisation
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Feuillards, Films étirables et Palettisation
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/537-films-%C3%A9tirables-manuels">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Film-estensibili-manuali-30px.png" />
                                  Films étirables manuels
                                </a>
                              </li>
                              <li>
                                <a href="/fr/538-palettes-et-plate-formes">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Pallet-bancali-e-pedane-30px.png" />
                                  Palettes et Plate-formes
                                </a>
                              </li>
                              <li>
                                <a href="/fr/541-protection-et-accessoires-de-palettisation">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Protezione-per-pallet-30px.png" />
                                  Protection et Accessoires de palettisation
                                </a>
                              </li>
                              <li>
                                <a href="/fr/539-feuillard-plastique-et-accessoires">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Reggia-in-plastica-e-accessori-30px.png" />
                                  Feuillard plastique et Accessoires
                                </a>
                              </li>
                              <li>
                                <a href="/fr/542-feuillard-textile-et-accessoires">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Reggia-in-tessuto-e-accessori-30px.png" />
                                  Feuillard textile et Accessoires
                                </a>
                              </li>
                              <li>
                                <a href="/fr/540-films-%C3%A9tirables-machine">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Film-estensibili-macchinabili-30px.png" />
                                  Films étirables pour banderoleuse
                                </a>
                              </li>
                              <li>
                                <a href="/fr/543-films-de-protection-et-couvre-palettes">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Film-protezione-e-copri-pallet-30px.png" />
                                  Films de protection et Couvre-palettes
                                </a>
                              </li>
                              <li>
                                <a href="/fr/544-feuillard-acier-et-accessoires">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Reggia-in-ferro-e-accessori-30px.png" />
                                  Feuillard acier et Accessoires
                                </a>
                              </li>
                              <li>
                                <a href="/fr/545-d%C3%A9rouleurs-de-films-%C3%A9tirables">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Dispenser-per-film-estensibile-30px.png" />
                                  Dérouleurs de film étirable
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/755-reggia-ecologica-in-carta">
                                  <img alt="" src="/menu/images/menu/3-Pallet, estensibile e reggia/Reggia-ecologica-in-carta-30px.png" />
                                  Feuillard papier écologique
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/29-feuillards-films-etirables-et-palettisation-"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/5-Sacchetti e buste/Sacchetti-e-buste-30px.png" />
                      Sacherie et Conditionnement
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Sacherie et Conditionnement
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/558-sachets-plastique-transparents">
                                  <img alt="" src="/menu/images/menu/5-Sacchetti e buste/Sacchetti-trasparenti-30px.png" />
                                  Sachets plastique transparents
                                </a>
                              </li>
                              <li>
                                <a href="/fr/559-sachets-plastique-avec-fermeture-%C3%A0-zip">
                                  <img alt="" src="/menu/images/menu/5-Sacchetti e buste/Sacchetti-con-chiusura-a-pressione-30px.png" />
                                  Sachets plastique avec fermeture à zip
                                </a>
                              </li>
                              <li>
                                <a href="/fr/560-gaines-et-films-thermor%C3%A9tractables">
                                  <img alt="" src="/menu/images/menu/5-Sacchetti e buste/Film-tubolari-e-termoretraibili-30px.png" />
                                  Gaines et Films thermorétractables
                                </a>
                              </li>
                              <li>
                                <a href="/fr/564-sachets-pour-emballages-sous-vide">
                                  <img alt="" src="/menu/images/menu/5-Sacchetti e buste/Sacchetti-per-sottovuoto-30px.png" />
                                  Sachets pour emballages sous-vide
                                </a>
                              </li>
                              <li>
                                <a href="/fr/561-sachets-antistatiques-et-de-protection">
                                  <img alt="" src="/menu/images/menu/5-Sacchetti e buste/Sacchetti-protettivi-e-antistatici-30px.png" />
                                  Sachets antistatiques et de protection
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/30-sacherie-et-conditionnement"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=558%7C559">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/5-fuorituttoFR-sacchetti-e-buste.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Shopper-e-confezioni-regalo-30px.png" />
                      Emballages boutique et Paquets cadeaux
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Emballages boutique et Paquets cadeaux
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/580-sacs-kraft">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Shopper-carta-30px.png" />
                                  Sacs krafts
                                </a>
                              </li>
                              <li>
                                <a href="/fr/579-sacs-plastiques-et-pellicul%C3%A9s">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Shopper-plastica-30px.png" />
                                  Sacs plastiques et pelliculés
                                </a>
                              </li>
                              <li>
                                <a href="/fr/581-emballages-pour-bouteilles">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Scatole-portabottiglie-30px.png" />
                                  Emballages pour bouteilles
                                </a>
                              </li>
                              <li>
                                <a href="/fr/582-boites-cadeaux-et-corbeilles-de-presentation">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Scatole-e-cesti-per-confezioni-regalo-30px.png" />
                                  Boîtes cadeaux et Corbeilles de présentation
                                </a>
                              </li>
                              <li>
                                <a href="/fr/583-noeuds-bolducs-et-frisures-de-calage">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Nastri-coccarde-e-paglietta-riempitiva-30px.png" />
                                  Noeuds, Bolducs et Frisures de calage
                                </a>
                              </li>
                              <li>
                                <a href="/fr/584-papiers-cadeaux-et-derouleurs">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Carta-da-regalo-e-taglierine-30px.png" />
                                  Papiers cadeaux et Dérouleurs
                                </a>
                              </li>
                              <li>
                                <a href="/fr/585-sachets-et-pochettes-cadeaux">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Sacchetti-e-buste-regalo-30px.png" />
                                  Sachets et Pochettes cadeaux
                                </a>
                              </li>
                              <li>
                                <a href="/fr/645-%C3%A9quipements-points-de-vente">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Sistemi-e-soluzioni-per-punti-vendita-30px.png" />
                                  Équipements points de vente
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/679-carta-da-banco-e-buste-farmacia">
                                  <img alt="" src="/menu/images/menu/7-Shopper e confezioni regalo/Carta-da-banco-e-buste-farmacia-30px.png" />
                                  Carta da banco e Buste farmacia
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/33-emballages-boutique-et-paquets-cadeaux"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=580%7C581%7C582%7C583%7C585%7C645">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/7-fuorituttoFR-shopper-e-confezioni-regalo.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Imballaggi-e-packaging-alimentare-30px.png" />
                      Supports et Emballages alimentaires
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Supports et Emballages alimentaires
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/628-emballages-isothermes">
                                  <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Imballaggi-e-scatole-isotermiche-30px.png" />
                                  Emballages isothermes
                                </a>
                              </li>
                              <li>
                                <a href="/fr/736-sacs-et-boites-pour-livraison-ou-a-emporter">
                                  <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Shopper-take-away-e-delivery-30px.png" />
                                  Sacs et Boîtes à emporter/livraison
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/563-sacchetti-per-alimenti">
                                  <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Sacchetti-per-alimenti-30px.png" />
                                  Sacchetti per alimenti
                                </a>
                              </li>
                              <li>
                                <a href="/fr/672-pots-barquettes-et-boites-pour-livraison-alimentaire">
                                  <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Contenitori-per-alimenti-da-asporto-30px.png" />
                                  Pots, Barquettes et Boîtes pour livraison
                                </a>
                              </li>
                              <li>
                                <a href="/fr/744-boites-repas-et-emballages-snacking">
                                  <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Contenitori-finger-food-30px.png" />
                                  Boîtes repas et Emballages snacking
                                </a>
                              </li>
                              <li>
                                <a href="/fr/611-vaisselle-et-emballages-jetables">
                                  <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Monouso-alimentare-30px.png" />
                                  Vaisselle et Emballages jetables
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/745-vaschette-alluminio">
                                  <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Vaschette-alluminio-30px.png" />
                                  Vaschette alluminio
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/747-stampi-vassoi-e-carta-per-alimenti">
                                  <img alt="" src="/menu/images/menu/8-Imballaggi e packaging alimentare/Stampi-vassoi-e-carte-per-alimenti-30px.png" />
                                  Stampi, Vassoi e Carta per alimenti
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/70-supports-et-emballages-alimentaires"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=563%7C672%7C736">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/8-fuorituttoFR-imballaggi-e-packaging-alimentare.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Transpallet-carrelli-e-magazzino-30px.png" />
                      Entrepôt et Stockage
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Entrepôt et Stockage
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                            <li style={{display:'none'}}>
                                <a href="/it/566-transpallet-manuali-ed-elettrici">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Transpallet-manuali-ed-elettrici-30px.png" />
                                  Transpalettes manuels et électriques
                                </a>
                              </li>
                              <li>
                                <a href="/fr/567-rayonnages-stockage-et-%C3%A9chelles">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Armadi-scaffali-e-scale-30px.png" />
                                  Rayonnages, Stockage et Échelles
                                </a>
                              </li>
                              <li>
                                <a href="/fr/571-gerbeurs">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Carrelli-elevatori-30px.png" />
                                  Gerbeurs
                                </a>
                              </li>
                              <li>
                                <a href="/fr/646-rolls-de-s%C3%A9curit%C3%A9-et-containers-grillag%C3%A9s">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Roll-container-e-ceste-in-filo-metallico-30px.png" />
                                  Rolls de sécurité et containers grillagés
                                </a>
                              </li>
                              <li>
                                <a href="/fr/574-postes-de-travail-et-accessoires">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Banchi-da-lavoro-e-accessori-30px.png" />
                                  Postes de travail et Accessoires
                                </a>
                              </li>
                              <li>
                                <a href="/fr/570-chariots-plateau-plein-4-roues">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Carrelli-a-pianale-4-ruote-30px.png" />
                                  Chariots plateau plein 4 roues
                                </a>
                              </li>
                              <li>
                                <a href="/fr/572-bacs-industriels-et-big-bag">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Contenitori-industriali-e-big-bag-30px.png" />
                                  Bacs industriels et Big Bag
                                </a>
                              </li>
                              <li>
                                <a href="/fr/568-bacs-de-rangement-et-grilles-plastique">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Contenitori-e-grigliati-in-plastica-30px.png" />
                                  Bacs de rangement et Grilles plastique
                                </a>
                              </li>
                              <li>
                                <a href="/fr/573-diables">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Carrelli-a-due-ruote-30px.png" />
                                  Diables
                                </a>
                              </li>
                              <li>
                                <a href="/fr/569-bacs-%C3%A0-bec-de-rangement">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Contenitori-a-bocca-di-lupo-30px.png" />
                                  Bacs à bec de rangement
                                </a>
                              </li>
                              <li>
                                <a href="/fr/575-cutters-universels">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Taglierini-universali-30px.png" />
                                  Cutters universels
                                </a>
                              </li>
                              <li>
                                <a href="/fr/576-balances-et-m%C3%A8tres">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Bilance-e-flessometri-30px.png" />
                                  Balances et Mètres
                                </a>
                              </li>
                              <li>
                                <a href="/fr/577-couteaux-de-securite">
                                  <img alt="" src="/menu/images/menu/9-Transpallet, carrelli e magazzino/Taglierini-e-cutter-di-sicurezza-30px.png" />
                                  Couteaux de sécurité
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/34-entrepot-stockage-et-deplacement-de-marchandises"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=567%7C576%7C577">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/9-fuorituttoFR-transpallet-carrelli-e-magazzino.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/10-Etichette/Etichette-30px.png" />
                      Étiquettes
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Étiquettes
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/601-%C3%A9tiquettes-et-films-transfert">
                                  <img alt="" src="/menu/images/menu/10-Etichette/Etichette-in-rotoli-e-ribbon-30px.png" />
                                  Étiquettes et Films transfert
                                </a>
                              </li>
                              <li>
                                <a href="/fr/602-%C3%A9tiquettes-d-exp%C3%A9dition-et-de-signalisation">
                                  <img alt="" src="/menu/images/menu/10-Etichette/Etichette-di-segnalazione-ed-indicatori-30px.png" />
                                  Étiquettes d'expédition et de signalisation
                                </a>
                              </li>
                              <li>
                                <a href="/fr/603-%C3%A9tiquettes-en-feuille">
                                  <img alt="" src="/menu/images/menu/10-Etichette/Etichette-in-fogli-30px.png" />
                                  Étiquettes en feuilles
                                </a>
                              </li>
                              <li>
                                <a href="/fr/604-imprimantes-et-distributeurs-d-%C3%A9tiquettes">
                                  <img alt="" src="/menu/images/menu/10-Etichette/Stampanti-e-dispenser-per-etichette-30px.png" />
                                  Imprimantes et Distributeur d'étiquettes
                                </a>
                              </li>
                              <li>
                                <a href="/fr/605-%C3%A9tiqueteuses">
                                  <img alt="" src="/menu/images/menu/10-Etichette/Prezzatrici-ed-etichette-30px.png" />
                                  Étiqueteuses
                                </a>
                              </li>
                              <li>
                                <a href="/fr/693-etiqueteuses-brother-et-accessoires">
                                  <img alt="" src="/menu/images/menu/10-Etichette/Etichettatrici-brother-e-consumabili-30px.png" />
                                  Étiqueteuses Brother et Accessoires
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/35-etiquettes"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=601%7C602%7C605">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/10-fuorituttoFR-etichette.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Macchinari-e-sistemi-di-riempimento-30px.png" />
                      Machines et Systèmes d'emballage
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Machines et Systèmes d'emballage
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/622-syst%C3%A8mes-de-protection-et-calage-bulles-et-coussins-d-air">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Sistemi-di-riempimento-ad-aria-30px.png" />
                                  Systèmes de protection et calage air et bulle
                                </a>
                              </li>
                              <li>
                                <a href="/fr/562-soudeuses">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Sigillatrici-30px.png" />
                                  Soudeuses
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/624-sistemi-di-riempimento-con-carta">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Sistemi-di-riempimento-con-carta-30px.png" />
                                  Sistemi di riempimento con carta
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/623-reggiatrici-e-tendireggia-a-batteria">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Reggiatrici-e-tendireggia-a-batteria-30px.png" />
                                  Reggiatrici e Tendireggia a batteria
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/626-fasciapallet">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Fasciapallet-30px.png" />
                                  Fasciapallet
                                </a>
                              </li>
                              <li>
                                <a href="/fr/734-humecteurs">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Umettatrici-30px.png" />
                                  Humecteurs
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/554-cucitrici-e-sparapunti">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Cucitrici-e-sparapunti-30px.png" />
                                  Agrafeuses industrielles
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/750-macchine-per-sottovuoto">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Macchine-per-sottovuoto-30px.png" />
                                  Macchine per sottovuoto
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/627-nastratrici">
                                  <img alt="" src="/menu/images/menu/11-Macchinari e sistemi di riempimento/Nastratrici-30px.png" />
                                  Nastratrici
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/36-machines-et-systemes-d-emballage"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=622">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/11-fuorituttoFR-macchinari-e-sistemi-di-riempimento.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Abbigliamento-da-lavoro-e-sicurezza-30px.png" />
                      Tenues de travail et de sécurité
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Tenues de travail et de sécurité
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/616-v%C3%AAtements-et-gants-jetables">
                                  <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Abbigliamento-e-guanti-monouso-30px.png" />
                                  Vêtements et Gants jetables
                                </a>
                              </li>
                              <li>
                                <a href="/fr/615-gants-de-travail-et-de-protection">
                                  <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Guanti-da-lavoro-30px.png" />
                                  Gants de travail et de protection
                                </a>
                              </li>
                              <li>
                                <a href="/fr/617-chaussures-de-s%C3%A9curit%C3%A9">
                                  <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Scarpe-antinfortunistiche-30px.png" />
                                  Chaussures de sécurité
                                </a>
                              </li>
                              <li>
                                <a href="/fr/618-v%C3%AAtements-de-travail-et-blouses">
                                  <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Abbigliamento-da-lavoro-e-camici-30px.png" />
                                  Vêtements de travail et blouses
                                </a>
                              </li>
                              <li>
                                <a href="/fr/619-dispositifs-de-protection-individuelle">
                                  <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Dispositivi-protezione-individuale-30px.png" />
                                  Dispositifs de protection individuelle
                                </a>
                              </li>
                              <li>
                                <a href="/fr/620-premiers-secours">
                                  <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Pronto-soccorso-30px.png" />
                                  Premiers secours
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/680-abbigliamento-alta-visibilita">
                                  <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Abbigliamento-alta-visibilita-30px.png" />
                                  Abbigliamento alta visibilità
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/732-abbigliamento-hotel-e-ristoranti">
                                  <img alt="" src="/menu/images/menu/12-Abbigliamento da lavoro e sicurezza/Abbigliamento-hotel-e-ristoranti-30px.png" />
                                  Tenues et Uniformes
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/37-tenue-de-travail-et-securite"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=615%7C617%7C618">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/12-fuorituttoFR-abbigliamento-da-lavoro-e-sicurezza.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/13-Pulizia professionale/Pulizia-professionale-30px.png" />
                      Entretien et Hygiène professionnelle
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Entretien et Hygiène professionnelle
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/594-papiers-absorbants-et-essuyage-industriel">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Carta-assorbente-e-pezzame-industriale-30px.png" />
                                  Papiers absorbants et Essuyage industriel
                                </a>
                              </li>
                              <li>
                                <a href="/fr/595-essuie-mains-et-accessoires">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Carta-asciugamani-e-dispenser-30px.png" />
                                  Essuie-mains et Accessoires
                                </a>
                              </li>
                              <li>
                                <a href="/fr/593-sacs-poubelle-pour-dechets">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Sacchi-immondizia-30px.png" />
                                  Sacs poubelle
                                </a>
                              </li>
                              <li>
                                <a href="/fr/596-papiers-toilettes-et-accessoires">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Carta-igienica-e-accessori-30px.png" />
                                  Papiers toilette et Accessoires
                                </a>
                              </li>
                              <li style={{display:'none'}}>
                                <a href="/it/752-contenitori-rifiuti-e-pattumiere">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Contenitori-rifiuti-e-pattumiere-30px.png" />
                                  Poubelles et Conteneurs pour déchets
                                </a>
                              </li>
                              <li>
                                <a href="/fr/598-mat%C3%A9riel-d-entretien">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Attrezzature-per-la-pulizia-30px.png" />
                                  Matériel d'entretien
                                </a>
                              </li>
                              <li>
                                <a href="/fr/599-nettoyage-des-sols-et-surfaces">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Detergenti-e-accessori-per-superfici-30px.png" />
                                  Nettoyage sols et surfaces et Accessoires
                                </a>
                              </li>
                              <li>
                                <a href="/fr/597-hygi%C3%A8ne-des-mains">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Detergenti-per-mani-30px.png" />
                                  Hygiène des mains
                                </a>
                              </li>
                              <li>
                                <a href="/fr/678-nettoyants-pour-industrie-alimentaire">
                                  <img alt="" src="/menu/images/menu/13-Pulizia professionale/Detergenza-per-industria-alimentare-e-ristorazione-30px.png" />
                                  Nettoyants pour industrie alimentaire
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/38-entretien-et-hygiene-professionnelle"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=599%7C678">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/13-fuorituttoFR-Pulizia-professionale.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/14-Prodotti per l ufficio/Prodotti-per-l-ufficio-30px.png" />
                      Papeterie et Fournitures de bureau
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            
                          Papeterie et Fournitures de bureau
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/607-feuilles-cahiers-post-its-et-%C3%A9tiquettes">
                                  <img alt="" src="/menu/images/menu/14-Prodotti per l ufficio/Carta-quaderni-post-it-ed-etichette.png" />
                                  Feuilles, Cahiers, Post-its et Étiquettes
                                </a>
                              </li>
                              <li>
                                <a href="/fr/613-stylos-crayons-et-surligneurs">
                                  <img alt="" src="/menu/images/menu/14-Prodotti per l ufficio/Penne-matite-ed-evidenziatori-30px.png" />
                                  Stylos, Crayons et Surligneurs
                                </a>
                              </li>
                              <li>
                                <a href="/fr/609-%C3%A9quipements-et-accessoires-de-bureau">
                                  <img alt="" src="/menu/images/menu/14-Prodotti per l ufficio/Cancelleria-30px.png" />
                                  Équipements et Accessoires de bureau
                                </a>
                              </li>
                              <li>
                                <a href="/fr/608-rangements-et-archives">
                                  <img alt="" src="/menu/images/menu/14-Prodotti per l ufficio/Archiviazione-30px.png" />
                                  Rangements et Archives
                                </a>
                              </li>
                              <li>
                                <a href="/fr/612-meubles-chaises-et-accessoires">
                                  <img alt="" src="/menu/images/menu/14-Prodotti per l ufficio/Arredo-ufficio-e-accessori-30px.png" />
                                  Meubles, Chaises et Accessoires
                                </a>
                              </li>
                              <li>
                                <a href="/fr/758-materiel-d-etiquetage-plastifieuses-et-destructeurs-de-documents">
                                  <img alt="" src="/menu/images/menu/14-Prodotti per l ufficio/Etichettatrici-plastificatrici-e-distruggidocumenti-30px.png" />
                                  Étiquetage, Plastifieuses et Destructeurs de documents
                                </a>
                              </li>
                              <li>
                                <a href="/fr/633-caf%C3%A9s-et-boissons">
                                  <img alt="" src="/menu/images/menu/14-Prodotti per l ufficio/Pausa-caffe-30px.png" />
                                  Café et Boissons
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/fr/content/39-papeterie-et-fournitures-de-bureau"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                          <div className="col-lg-12 wstadsize01 clearfix">
                            <a href="/fr/promotions?q=607%7C612">
                              <img alt=""
                                src="/menu/images/banner fuoritutto/14-fuorituttoFR-Prodotti-per-l-ufficio.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <a href="#">
                      <img alt="" src="/menu/images/menu/15-Hotellerie/Hotellerie-30px.png" />
                      Hôtellerie
                    </a>
                    <div className="wstitemright clearfix height100">
                      <div className="container-fluid height100">
                        <div className="row">
                          <div className="wstheadingtitolo clearfix ">
                            Hôtellerie
                          </div>
                          <div className="col-lg-12 col-md-12 clearfix">
                            <ul className="wstliststy02 clearfix">
                              <li>
                                <a href="/fr/716-cosmetiques-et-accessoires-d-accueil">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Linea-cortesia-30px.png" />
                                  Accessoires d'accueil et Cosmétiques
                                </a>
                              </li>
                              <li>
                                <a href="/fr/717-accessoires-de-salle-de-bain">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Linea-bagno-30px.png" />
                                  Accessoires de salle de bain
                                </a>
                              </li>
                              <li>
                                <a href="/fr/720-salle-de-restaurant-et-buffet">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Tavola-e-buffet-30px.png" />
                                  Salle de restaurant et Buffet
                                </a>
                              </li>
                              <li>
                                <a href="/fr/722-tenues-et-uniformes">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Abbigliamento-hotel-e-ristoranti-30px.png" />
                                  Tenues et Uniformes
                                </a>
                              </li>
                              <li>
                                <a href="/fr/714-mobilier-et-accessoires-de-chambre">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Linea-camera-30px.png" />
                                  Mobiliers et Accessoires de chambre
                                </a>
                              </li>
                              <li>
                                <a href="/fr/719-reception-et-service-d-etage">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Reception-e-piani-30px.png" />
                                  Réception et Service d'étage
                                </a>
                              </li>
                              <li>
                                <a href="/fr/721-spa-et-bien-etre">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Wellness-e-SPA-30px.png" />
                                  Spa et Bien-être
                                </a>
                              </li>
                              <li>
                                <a href="/fr/content/38-entretien-et-hygiene-professionnelle">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Pulizia-professionale-30px.png" />
                                  Nettoyage et Entretien professionnel
                                </a>
                              </li>
                              <li>
                                <a href="/fr/content/70-supports-et-emballages-alimentaires">
                                  <img alt="" src="/menu/images/menu/15-Hotellerie/Imballaggi-e-packaging-alimentare-30px.png" />
                                  Emballages alimentaires
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="wstheading viewAllSubMega clearfix allcategory ">
                            <a
                              href="/content/48-hotellerie"
                              className="wstmorebtn"
                            >
                              Voir la catégorie
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </li>
          <li aria-haspopup="true">
            <a href="#" className="navtext">
              <span>Pour votre activité</span>
            </a>
            <div className="wsmegamenu clearfix">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-md-12">
                    <ul className="wstliststy04 clearfix">
                      <li className="wstheading clearfix">
                        <a href="/fr/662-emballages-et-protection">
                          <img alt=""
                            src="/menu/images/per la tua attivita/menu-attivita-277x185-imballaggio.png"
                            alt=" "
                          />
                          Emballages & Protection
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <ul className="wstliststy04 clearfix">
                      <li className="wstheading clearfix">
                        <a href="/fr/663-livraisons-e-commerce">
                          <img alt=""
                            src="/menu/images/per la tua attivita/menu-attivita-277x185-spedizioni-e-e-commerce.png"
                            
                          />
                          Envois & E-commerce
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <ul className="wstliststy04 clearfix">
                      <li className="wstheading clearfix">
                        <a href="/fr/664-Logistique,-Entrepôt-et-Stockage">
                          <img alt=""
                            src="/menu/images/per la tua attivita/menu-attivita-277x185-logistica-e-magazzino.png"
                            
                          />
                          Logistique, Entrepôt & Stockage
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <ul className="wstliststy04 clearfix">
                      <li className="wstheading clearfix">
                        <a href="/fr/629-emballages-boutique">
                          <img alt=""
                            src="/menu/images/per la tua attivita/menu-attivita-277x185-negozi.png"
                            
                          />
                          Emballages boutique
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li aria-haspopup="true">
            <a href="/fr/499-produits-éco-responsables" className="navtext eco">
              <span>Emballages éco-responsables</span>
            </a>
          </li>
          <li style={{display:'none'}} aria-haspopup="true">
            <a href="/it/content/25-imballaggi-personalizzati" className="navtext">
              <span>Prodotti personalizzati</span>
            </a>
          </li>
          <li aria-haspopup="true">
            <a href="/fr/promotions" className="navtext evidence">
              <span>Le Coin des promos</span>
            </a>
          </li>
          <p className="onlyMobile" style={{ marginTop: 25, display:'none' }}>
            <a href="/it/content/66-servizi-corporate-propac">
              Acquisti grandi volumi?
            </a>
          </p>
          <p className="onlyMobile">
            <a href="/fr/module/obs_quickorder/quickorder">Achat rapide</a>
          </p>
          <p className="onlyMobile">
            <a href="/fr/content/7-nous-contacter">Contacts</a>
          </p>
          <p className="onlyMobile">
            <a href="/fr/content/8-faq">FAQ</a>
          </p>
          <p className="onlyMobile">
            <a href="/fr/mon-compte">
              <iconify-icon icon="mingcute:user-2-fill"> </iconify-icon>Mon compte
            </a>
          </p>
          <p className="onlyMobile">
            <a href="/fr/module/ttproductwishlist/mywishlist">
              <iconify-icon icon="ph:heart-fill"> </iconify-icon>Favoris
            </a>
          </p>
          <p className="onlyMobile">
            <a href="/fr/?mylogout=">
              <iconify-icon icon="ion:log-out"> </iconify-icon>Se déconnecter
            </a>
          </p>
        </ul>
      </nav>
    </div>
  </div>
</>


</React.Fragment>
  );
}

export default App;
